import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import "./mobileCard.css";
export default function MobileCard() {
  const [isHovering1, setIsHovering1] = useState(false);
  const [isHovering2, setIsHovering2] = useState(false);
  const [isHovering3, setIsHovering3] = useState(false);
  const [isHovering4, setIsHovering4] = useState(false);
  const [isHovering5, setIsHovering5] = useState(false);

  useEffect(() => {
    const imagesToPreload = [
      "https://dojoitsolutions.com/wp-content/uploads/2024/09/mobileCard1-icon2.svg",
      "https://dojoitsolutions.com/wp-content/uploads/2024/09/mobileCard2-icon2.svg",
      "https://dojoitsolutions.com/wp-content/uploads/2024/09/mobileCard3-icon2.svg",
      "https://dojoitsolutions.com/wp-content/uploads/2024/09/mobileCard4-icon2.svg",
      "https://dojoitsolutions.com/wp-content/uploads/2024/09/mobileCard5-icon2.svg",
    ];

    imagesToPreload.forEach((src) => {
      const link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = src;
      document.head.appendChild(link);
    });
  }, []);

  return (
    <section className="mobileCard">
      <div className="container2">
        {/* <Container
          className="mx-auto containerCards"
          style={{ maxWidth: "90%" }}
        > */}
        <div className="flex cradPadding">
          <div
            onMouseEnter={() => setIsHovering1(true)}
            onMouseLeave={() => setIsHovering1(false)}
            className="cards"
          >
            {!isHovering1 && (
              <div className="topIcon">
                <img
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/mobileCard1-icon1.svg"
                  alt="photo"
                />
              </div>
            )}
            {isHovering1 && (
              <div className="topIcon">
                <img
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/mobileCard1-icon2.svg"
                  alt="photo"
                />
              </div>
            )}
            <h2>Mobile App Development Consultation</h2>
            <p className="p-1">
              Mobile applications come with a set of user base and customer
              expectations. We help businesses align their ideas with
              expectations and create a mobile-specific user base. Our
              consultants help you choose the best mobile platform.
            </p>
            <p className="p-2">Dojo Solutions</p>
            <img
              className="tri01"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Vector0.svg"
              alt="photo"
            />
            <img
              className="log"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Vector1.svg"
              alt="photo"
            />
          </div>
          <div
            onMouseEnter={() => setIsHovering2(true)}
            onMouseLeave={() => setIsHovering2(false)}
            className="cards"
          >
            {!isHovering2 && (
              <div className="topIcon">
                <img
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/mobileCard2-icon1.svg"
                  alt="photo"
                />
              </div>
            )}
            {isHovering2 && (
              <div className="topIcon">
                <img
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/mobileCard2-icon2.svg"
                  alt="photo"
                />
              </div>
            )}
            <h2>Mobile UI/UX Design</h2>
            <p className="p-1">
              Mobile app development is one of the most expensive ecosystems
              because it involves several devices operating within it. Our
              talented team of designers is experienced in catering to the
              unique demands of our clients across all mobile devices and
              versions.
            </p>
            <p className="p-2">Dojo Solutions</p>
            <img
              className="card2-1"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-1.svg"
              alt="photo"
            />
            <img
              className="card2-2"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-2.svg"
              alt="photo"
            />
            <img
              className="card2-3"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-3.svg"
              alt="photo"
            />
          </div>
          <div
            onMouseEnter={() => setIsHovering3(true)}
            onMouseLeave={() => setIsHovering3(false)}
            className="cards"
          >
            {!isHovering3 && (
              <div className="topIcon">
                <img
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/mobileCard3-icon1.svg"
                  alt="photo"
                />
              </div>
            )}
            {isHovering3 && (
              <div className="topIcon">
                <img
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/mobileCard3-icon2.svg"
                  alt="photo"
                />
              </div>
            )}
            <h2>Custom Mobile Application Development</h2>
            <p className="p-1">
              Our mobile application developers carry expertise in building
              robust, scalable mobile solutions. We create custom mobile
              software for a range of industries across the globe. We help you
              build a solution that presents your authentic voice in a store
              with millions of apps.
            </p>
            <p className="p-2">Dojo Solutions</p>
            <img
              className="card2-1"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-1.svg"
              alt="photo"
            />
            <img
              className="card2-2"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-2.svg"
              alt="photo"
            />
            <img
              className="card2-3"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-3.svg"
              alt="photo"
            />
          </div>
          <div
            onMouseEnter={() => setIsHovering4(true)}
            onMouseLeave={() => setIsHovering4(false)}
            className="cards"
          >
            {!isHovering4 && (
              <div className="topIcon">
                <img
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/mobileCard4-icon1.svg"
                  alt="photo"
                />
              </div>
            )}
            {isHovering4 && (
              <div className="topIcon">
                <img
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/mobileCard4-icon2.svg"
                  alt="photo"
                />
              </div>
            )}
            <h2>Multi-Platform Deployment</h2>
            <p className="p-1">
              We are an application development company specializing in seamless
              integration and deployment, irrespective of which platform you
              want to be on.
            </p>
            <p className="p-2">Dojo Solutions</p>
            <img
              className="card4-1"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card4-1.svg"
              alt="photo"
            />
            <img
              className="card4-2"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card4-2.svg"
              alt="photo"
            />
            <img
              className="card4-3"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card4-3.svg"
              alt="photo"
            />
          </div>
          <div
            onMouseEnter={() => setIsHovering5(true)}
            onMouseLeave={() => setIsHovering5(false)}
            className="cards"
          >
            {!isHovering5 && (
              <div className="topIcon">
                <img
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/mobileCard5-icon1.svg"
                  alt="photo"
                />
              </div>
            )}
            {isHovering5 && (
              <div className="topIcon">
                <img
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/mobileCard5-icon2.svg"
                  alt="photo"
                />
              </div>
            )}
            <h2>Mobile Software Testing</h2>
            <p className="p-1">
              Our mobile development process is engineered to present security
              and performance first. We ensure that your mobile application
              development is glitch-free and has zero lags. We perform a
              combination of manual and automated testing processes to ensure
              future-ready software development.
            </p>
            <p className="p-2">Dojo Solutions</p>
            <img
              className="card5-1"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card5-1.svg"
              alt="photo"
            />
            <img
              className="card5-2"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card5-2.svg"
              alt="photo"
            />
          </div>
        </div>
        {/* </Container> */}
      </div>
    </section>
  );
}
