import "./footer.css";
import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
export default function Footer() {
  return (
    <footer>
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <div className="footer">
            <Link to="/home">
              <img
                className="logo"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/logoo.svg"
                alt="photo"
              />
            </Link>
            <div className="social">
              <Link
                target="_blank"
                to={
                  "https://www.facebook.com/login/?next=https%3A%2F%2Fwww.facebook.com%2FDojo-Design-Development-106180328413281"
                }
              >
                <button>
                  <img
                    src="http://dojoitsolutions.com/wp-content/uploads/2024/08/facebook-1.svg"
                    alt="photo"
                  />
                </button>
              </Link>
              <Link
                target="_blank"
                to={
                  "https://www.instagram.com/accounts/login/?next=https%3A%2F%2Fwww.instagram.com%2Fdojo.dev%2F&is_from_rle"
                }
              >
                <button>
                  <img
                    className="inst"
                    src="http://dojoitsolutions.com/wp-content/uploads/2024/08/insta-1.svg"
                    alt="photo"
                  />
                </button>
              </Link>
            </div>
            <p>© 2022. All rights reserved by dojoitsolutions</p>
          </div>
        </Container>
      </div>
    </footer>
  );
}
