import Container from "react-bootstrap/Container";
import "./heroMobile.css";
import { HashLink } from "react-router-hash-link";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import ContactFormm from "../contactForm/formApi";

import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
export default function HeroMobile() {
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const handleCaptchaChange = () => {
    setCaptchaVerified(true);
  };
  const [showaction, setshowaction] = useState(false);

  return (
    <section className="heroMobile heroHeight">
      <div className="container2">
        <div className="phone">
          <img
            src="https://dojoitsolutions.com/wp-content/uploads/2024/09/phone.svg"
            alt="photo"
          />
        </div>
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <h1>
            Mobile Application<span> Services</span>
          </h1>
          <p className="p-1 subtitleHero">
            If you are planning to build a Web Application for your business,
            it’s vital to understand the whole web app development process to
            determine whether it will fit your market niche and marketing goals.
          </p>
          <button
            onClick={() => {
              setshowaction(true);
            }}
          >
            Consult Our Experts
          </button>
          <p className="p-2 linksBar">
            <HashLink className="linkHero" to={"/home"}>
              Home -
            </HashLink>
            <HashLink className="linkHero" to={"/servicesPage"}>
              <span> Services</span> -
            </HashLink>
            <span> Mobile Application Services</span>
          </p>
          {showaction && (
            <div className="fixedAction">
              <div className="formAction">
                <button
                  onClick={() => {
                    setshowaction(false);
                  }}
                  className="closer"
                >
                  x
                </button>
                <ContactFormm />
              </div>
            </div>
          )}
        </Container>
      </div>
    </section>
  );
}
