import HeroPortfolio from "../components/heroPortfolio/HeroPortfolio";
import Nav from "../components/navbar/Nav";
import Section1 from "../components/section1/Section1";
import Section2 from "../components/section2/Section2";
import Section3 from "../components/section3/Section3";
import Section4 from "../components/section4/Section4";
import Section5 from "../components/section5/section5";
import Section6 from "../components/section6/Section6";
import Section7 from "../components/section7/Section7";
import Section8 from "../components/section8/Section8";
import Section9 from "../components/section9/Section9";
import Contact from "../components/contact/Contact";
import Footer from "../components/footer/Footer";
import { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Section10 from "../components/section10/Section10";
import Section11 from "../components/section11/Section11";
import Section12 from "../components/section12/Section12";
export default function Portfolio() {
  const [showScrollBTN, setshowScrollBTN] = useState(false);
  const [clickShowScrollBTN, setClickShowScrollBTN] = useState(false);
  useEffect(() => {
    // Save scroll position before refresh
    const handleBeforeUnload = () => {
      sessionStorage.setItem("scrollPosition", window.scrollY);
    };

    // Restore scroll position after refresh
    const restoreScrollPosition = () => {
      const scrollPosition = sessionStorage.getItem("scrollPosition");
      if (scrollPosition) {
        window.scrollTo(0, parseInt(scrollPosition));
        sessionStorage.removeItem("scrollPosition");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    restoreScrollPosition();

    // Existing scroll event listener
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        setshowScrollBTN(true);
      } else {
        setshowScrollBTN(false);
      }
    });

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  // const myWepRef = useRef(null);
  // useEffect(() => {
  //   myWepRef.current?.scrollIntoView({ behavior: "smooth" });
  // }, []);
  const myWepRef2 = useRef(null);
  useEffect(() => {
    myWepRef2.current?.scrollIntoView({ behavior: "smooth" });
  }, [clickShowScrollBTN]);
  return (
    <div ref={myWepRef2}>
      <div>
        <Nav />
        <HeroPortfolio />
        <div className="divider2">
          <div className="line"></div>
          <span></span>
        </div>
        <Section1 />
        <Section8 />
        <Section2 />
        <Section4 />
        <Section5 />
        <Section3 />
        <Section6 />
        <Section7 />
        <Section9 />
        <Section10 />
        <Section11 />
        <Section12 />
        <div className="divider3">
          <div className="line"></div>
          <span></span>
        </div>
        <Contact />
        <div className="divider4">
          <div className="line"></div>
          <span></span>
        </div>
        <Footer />
        <Link
          onClick={() => {
            if (clickShowScrollBTN) {
              setClickShowScrollBTN(false);
            } else {
              setClickShowScrollBTN(true);
            }
          }}
          style={{ opacity: showScrollBTN ? 1 : 0, transition: "0.7s" }}
        >
          <button className="icon-chevron-upp">
            <img
              src="https://dojoitsolutions.com/wp-content/uploads/2024/10/arrow.svg"
              alt="photo"
            />
            {""}
          </button>
        </Link>
      </div>
    </div>
  );
}
