import React, { useState } from "react";
import "./contactForm.css";
import ReCAPTCHA from "react-google-recaptcha";

function MyForm() {
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const handleCaptchaChange = () => {
    setCaptchaVerified(true);
  };
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    message: "",
    email: "",
    phone: "",
    service: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaVerified) {
      alert("You must prove that you are not a robot");
      return;
    }
    try {
      const response = await fetch("https://dojoitsolutions.com/email2.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Your request has been received successfully");

        window.location.reload();
        console.log("Message sent successfully");
        // Display a success message to the user
      } else {
        alert("Error sending message");
        console.error("Error sending message");
        // Display an error message to the user
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle errors, e.g., network errors or server-side issues
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="input">
        <label htmlFor="firstName">First Name</label>
        <input
          type="text"
          id="firstName"
          name="firstName"
          placeholder="First Name"
          value={formData.firstName}
          onChange={handleChange}
          required
        />
      </div>
      <div className="input">
        <label htmlFor="lastName">Last Name</label>
        <input
          type="text"
          name="lastName"
          id="lastName"
          placeholder="Last Name"
          value={formData.lastName}
          onChange={handleChange}
          required
        />
      </div>
      <div className="input">
        <label htmlFor="email">Your Email</label>
        <input
          id="email"
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="input">
        <label htmlFor="number">Phone Number</label>
        <input
          type="tel"
          id="number"
          name="phone"
          placeholder="Phone Number"
          value={formData.phone}
          onChange={handleChange}
          required
        />
      </div>
      <div className="input">
        <label htmlFor="service">Service?</label>
        <select
          name="service"
          id="service"
          value={formData.service}
          onChange={handleChange}
        >
          <option value="">Select a Service</option>
          <option value="1">Web Application Development</option>
          <option value="2">Mobile Application Development</option>
          <option value="3">SaaS Application Development</option>
          <option value="4">UI/UX Designing</option>
          <option value="5">Software Application Maintenance</option>
          <option value="6">Software Testing & QA</option>
          {/* Add more options as needed */}
        </select>
      </div>
      <div className="input">
        <label htmlFor="message">Message</label>
        <textarea
          id="message"
          name="message"
          placeholder="Message"
          value={formData.message}
          onChange={handleChange}
          required
        />
      </div>
      <ReCAPTCHA
        sitekey="6LeWZEMqAAAAAI65SXRFMIBcSQlW0KghLgZVPCj-"
        onChange={handleCaptchaChange}
      />
      <button className="submit" type="submit">
        Submit
      </button>
    </form>
  );
}
export default MyForm;
