import Container from "react-bootstrap/Container";
import "./heroServicesPage.css";
import { HashLink } from "react-router-hash-link";
import { useState } from "react";
import ContactFormm from "../contactForm/formApi";

import ReCAPTCHA from "react-google-recaptcha";

export default function HeroServicesPage() {
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const handleCaptchaChange = () => {
    setCaptchaVerified(true);
  };
  const [showaction, setshowaction] = useState(false);

  return (
    <section className="heroServicesPage heroHeight">
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <h1>
            OUR<span> SERVICES</span>
          </h1>
          <p className="p-1 subtitleHero">
            We are a passionate and creative team driven by one relentless
            pursuit: to build and design.
          </p>
          <button
            onClick={() => {
              setshowaction(true);
            }}
          >
            Get Started
          </button>
          {/* <p className="p-2">
            <HashLink className="linkHero" to={"/home"}>
              Home -
            </HashLink>
            <span> Services</span>
          </p> */}
          {showaction && (
            <div className="fixedAction">
              <div className="formAction">
                <button
                  onClick={() => {
                    setshowaction(false);
                  }}
                  className="closer"
                >
                  x
                </button>
                <ContactFormm />
              </div>
            </div>
          )}
        </Container>{" "}
        <div className="phone">
          <img
            src="https://dojoitsolutions.com/wp-content/uploads/2024/10/graphics4.svg"
            alt="photo"
          />
        </div>
      </div>
    </section>
  );
}
