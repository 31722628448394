import React, { useRef, useEffect, useState } from "react";

import Container from "react-bootstrap/Container";
import "./roadmap.css";

export default function Roadmap() {
  const [viewClass, setViewClass] = useState(false);
  const [viewClass2, setViewClass2] = useState(false);
  const [viewClass3, setViewClass3] = useState(false);
  const [viewClass4, setViewClass4] = useState(false);
  const [viewClassMini, setViewClassMini] = useState(false);
  const [viewClass2Mini, setViewClass2Mini] = useState(false);
  const [viewClass3Mini, setViewClass3Mini] = useState(false);
  const [viewClass4Mini, setViewClass4Mini] = useState(false);
  const [viewClassMedi, setViewClassMedi] = useState(false);

  const [imageSrc, setImageSrc] = useState("image1.jpg");
  const [imageClass, setImageClass] = useState("normal");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 700) {
        setImageSrc(
          "https://dojoitsolutions.com/wp-content/uploads/2024/09/miniRoadweb.svg"
        );
        setImageClass("mini");
      } else {
        setImageSrc(
          "https://dojoitsolutions.com/wp-content/uploads/2024/09/roadmap-services.svg"
        );
        setImageClass("normal");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // تشغيل الدالة عند تحميل الصفحة لأول مرة

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 1000 && window.innerWidth > 700) {
        setViewClass(true);
      }
      if (
        window.scrollY >= 1250 &&
        window.innerWidth <= 1160 &&
        window.innerWidth > 700
      ) {
        setViewClassMedi(true);
      }
      if (window.scrollY >= 1400 && window.innerWidth > 700) {
        setViewClass2(true);
      }
      if (window.scrollY >= 1630 && window.innerWidth <= 700) {
        setViewClassMini(true);
      }
      if (window.scrollY >= 1850 && window.innerWidth > 700) {
        setViewClass3(true);
      }
      if (window.scrollY >= 1835 && window.innerWidth <= 700) {
        setViewClass2Mini(true);
      }
      if (window.scrollY >= 2150 && window.innerWidth <= 700) {
        setViewClass3Mini(true);
      }
      if (window.scrollY >= 2300 && window.innerWidth > 700) {
        setViewClass4(true);
      }
      if (window.scrollY >= 2550 && window.innerWidth <= 700) {
        setViewClass4Mini(true);
      }
    });
  }, []);

  return (
    <section className="roadmap-services">
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <div className="text-top">
            <h2 className="topH2">
              Web Development <span>Cycle</span>
            </h2>
            <p className="topP">
              Our development cycle helps turn your great ideas into profitable
              business solutions.
            </p>
          </div>
          <div className="roadmap-ser">
            {/* <img src={imageSrc} alt="" /> */}
            <img
              className={imageClass === "normal" ? "road" : "miniRoad"}
              src={imageSrc}
              alt="photo"
            />
            <img
              className={
                viewClass || viewClassMini || viewClassMedi
                  ? "step1"
                  : "step1 notView1"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/roadmap-services-1.svg"
              alt="photo"
            />
            <img
              className={
                viewClass || viewClassMini || viewClassMedi
                  ? "step1title"
                  : "step1title notView1title"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step1tiitle.svg"
              alt="photo"
            />
            <img
              className={
                viewClass2 || viewClass2Mini ? "step2" : "step2 notView2"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/roadmap-services-2.svg"
              alt="photo"
            />
            <img
              className={
                viewClass2 || viewClass2Mini
                  ? "step2title"
                  : "step2title notView2title"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step2title-2.svg"
              alt="photo"
            />
            <img
              className={
                viewClass3 || viewClass3Mini ? "step3" : "step3 notView3"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/roadmap-services-3.svg"
              alt="photo"
            />
            <img
              className={
                viewClass3 || viewClass3Mini
                  ? "step3title"
                  : "step3title notView3title"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step3title.svg"
              alt="photo"
            />
            <img
              className={
                viewClass4 || viewClass4Mini ? "step4" : "step4 notView4"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/roadmap-services-4.svg"
              alt="photo"
            />
            <img
              className={
                viewClass4 || viewClass4Mini
                  ? "step4title"
                  : "step4title notView4title"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step4title.svg"
              alt="photo"
            />
          </div>
        </Container>
      </div>
    </section>
  );
}
