// import React, { useEffect, useState } from "react";
import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";

import Container from "react-bootstrap/Container";
import "./section9.css";

export default function Section9() {
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: false, amount: 0.3 });

  return (
    <section className="section9" ref={sectionRef} id="studly">
      <div className="overlay"></div>
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <motion.div
            className="motion"
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : 200 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <a href="https://studly.co/" target="_blank">
              <img
                className="mobilelink9"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/10/studly-web-app11.png"
                alt="photo"
              />
            </a>
          </motion.div>
          <motion.div
            className="mainTitle9"
            initial={{ opacity: 0, x: -200 }}
            animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : -200 }}
            transition={{ duration: 0.1 }}
          >
            <h2>Studly</h2>
            <p>
              We developed and designed the web application for Studly, a
              project management software.
            </p>
          </motion.div>
          <motion.div
            className="subTitle9"
            initial={{ opacity: 0, x: -200 }}
            animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : -200 }}
            transition={{ duration: 0.1, delay: 0.3 }}
          >
            <p>
              We included a dynamic dashboard and user interface/experience
              (UI/UX), using React.js for the front end and Node.js with MongoDB
              for the back end.
            </p>
            <a href="https://studly.co/" target="_blank">
              VISIT SITE
            </a>
          </motion.div>{" "}
        </Container>
      </div>
      <div className="dividerPortfolio2">
        <img
          className="dividerPortfolio"
          src="https://dojoitsolutions.com/wp-content/uploads/2024/10/newyellwo.svg"
          alt="photo"
        />
      </div>
    </section>
  );
}
