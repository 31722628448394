// import React, { useEffect, useState } from "react";
import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import Container from "react-bootstrap/Container";
import "./section7.css";

export default function Section7() {
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: false, amount: 0.3 });

  return (
    <section className="section7" ref={sectionRef} id="see">
      <div className="overlay"></div>
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          {/* <img
            className="blue"
            src="https://dojoitsolutions.com/wp-content/uploads/2024/09/blueportfolio.svg"
            alt=""
          /> */}
          <motion.div
            className="motion"
            initial={{ opacity: 0, x: -200 }}
            animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : -200 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <a href="https://seemoreod.com/" target="_blank">
              <img
                className="mobilelink7"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/10/seemore11.png"
                alt="photo"
              />
            </a>
          </motion.div>
          <motion.div
            className="mainTitle7"
            initial={{ opacity: 0, x: -200 }}
            animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : -200 }}
            transition={{ duration: 0.1 }}
          >
            <h2>See More</h2>
            <p>When you see more, you do more.</p>
          </motion.div>
          <motion.div
            className="subTitle7"
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : 200 }}
            transition={{ duration: 0.1, delay: 0.3 }}
          >
            <p>
              The comprehensive eye exam includes a full assessment of your
              ocular health and eyeglass prescription.
            </p>
            <a href="https://seemoreod.com/" target="_blank">
              VISIT SITE{" "}
            </a>
          </motion.div>
        </Container>
      </div>
      <div className="dividerPortfolio2">
        <img
          className="dividerPortfolio"
          src="https://dojoitsolutions.com/wp-content/uploads/2024/10/newblue.svg"
          alt="photo"
        />
      </div>
    </section>
  );
}
