// import React, { useEffect, useState } from "react";
import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";

import Container from "react-bootstrap/Container";
import "./section12.css";
export default function Section12() {
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: false, amount: 0.3 });

  return (
    <section className="section12" ref={sectionRef} id="mi">
      <div className="overlay"></div>
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <motion.div
            className="motion"
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : 200 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <a
              href="https://www.figma.com/proto/yyXx47hlxTbxLlKl4pxhkg/Albarak-Food-Ordering?node-id=1-3&node-type=frame&t=57GuSVmTev7JkvIi-0&scaling=contain&content-scaling=fixed&page-id=0%3A1"
              target="_blank"
            >
              <img
                className={"mobilelink12"}
                src="https://dojoitsolutions.com/wp-content/uploads/2024/10/mepp11.png"
                alt="photo"
              />
            </a>
          </motion.div>
          <motion.div
            className="mainTitle12"
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : 200 }}
            transition={{ duration: 0.1 }}
          >
            <h2>Mi Tienda</h2>
            <p>Don't hesitate to know.</p>
          </motion.div>
          <motion.div
            className="subTitle12"
            initial={{ opacity: 0, x: -200 }}
            animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : -200 }}
            transition={{ duration: 0.1, delay: 0.3 }}
          >
            <p>
              Dojo created the Mi Tenda web appliction for management and as a
              result it became a very useful application.
            </p>
            <a
              href="https://www.figma.com/proto/yyXx47hlxTbxLlKl4pxhkg/Albarak-Food-Ordering?node-id=1-3&node-type=frame&t=57GuSVmTev7JkvIi-0&scaling=contain&content-scaling=fixed&page-id=0%3A1"
              target="_blank"
            >
              VISIT SITE
            </a>
          </motion.div>
          {/* <div className="dividerPortfolio2">
            <img
              className="dividerPortfolio"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/yellowLine.svg"
              alt=""
            />
          </div> */}
        </Container>
      </div>
    </section>
  );
}
