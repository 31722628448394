import { useState, useEffect } from "react";
import "./sassRoadmap.css";

export default function SassRoadmap() {
  const [image, setImage] = useState("image1.jpg");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 700) {
        setImage(
          "https://dojoitsolutions.com/wp-content/uploads/2024/09/mini-saasRoadmap-.svg"
        );
      } else {
        setImage(
          "https://dojoitsolutions.com/wp-content/uploads/2024/09/sassRoadmap.svg"
        );
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <section className="sassRoadmap">
      <div className="container2">
        <h2 className="topH2">
          <span>SaaS </span>Development Cycle
        </h2>
        <p className="topP">
          Our Solution Development Cycle helps turn your great ideas into
          profitable business solutions.
        </p>
        <div className="mobRoadmap">
          <img src={image} alt="photo" />
        </div>
      </div>
    </section>
  );
}
