import Container from "react-bootstrap/Container";
import "./heroUx.css";
import { HashLink } from "react-router-hash-link";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import ContactFormm from "../contactForm/formApi";

export default function HeroUx() {
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const handleCaptchaChange = () => {
    setCaptchaVerified(true);
  };
  const [showaction, setshowaction] = useState(false);

  return (
    <section className="heroUx heroHeight">
      <div className="container2">
        <div className="phone">
          <img
            src="https://dojoitsolutions.com/wp-content/uploads/2024/10/graphics4.svg"
            alt="photo"
          />
        </div>
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <h1>
            UI /<span> UX </span>DESIGN
          </h1>
          <p className="p-1 subtitleHero">
            With our defined approach and step by step process, we deliver
            beyond what’s expected.
          </p>
          <button
            onClick={() => {
              setshowaction(true);
            }}
          >
            Consult Our Experts
          </button>
          <p className="p-2 linksBar">
            <HashLink className="linkHero" to={"/home"}>
              Home -
            </HashLink>
            <HashLink className="linkHero" to={"/servicesPage"}>
              <span> Services</span> -
            </HashLink>
            <span> UI / UX DESIGN</span>
          </p>
          {showaction && (
            <div className="fixedAction">
              <div className="formAction">
                <button
                  onClick={() => {
                    setshowaction(false);
                  }}
                  className="closer"
                >
                  x
                </button>
                <ContactFormm />ss
              </div>
            </div>
          )}
        </Container>
      </div>
    </section>
  );
}
