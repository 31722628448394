import Container from "react-bootstrap/Container";
import "./heroAbout.css";
import ContactFormm from "../contactForm/formApi";

import { HashLink } from "react-router-hash-link";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

export default function HeroAbout() {
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const handleCaptchaChange = () => {
    setCaptchaVerified(true);
  };
  const [showaction, setshowaction] = useState(false);

  return (
    <section className="heroAbout heroHeight">
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <h1>
            About<span> Us</span>
          </h1>
          <p className="p-1 subtitleHero ">
            We are an innovative and experienced group of people who love to
            create. With clients scattered all around the world, our projects
            have ranged from mobile app development to web app and website
            development, artificial intelligence, and beyond.
          </p>
          <button
            onClick={() => {
              setshowaction(true);
            }}
          >
            Get Started
          </button>
          {/* <p className="p-2">
            <HashLink className="linkHero" to={"/home"}>
              Home -
            </HashLink>
            <span> Mobile Application Services</span>
          </p> */}
          {showaction && (
            <div className="fixedAction">
              <div className="formAction">
                <button
                  onClick={() => {
                    setshowaction(false);
                  }}
                  className="closer"
                >
                  x
                </button>
                <ContactFormm />
              </div>
            </div>
          )}
        </Container>
        <div className="phone">
          <img
            src="https://dojoitsolutions.com/wp-content/uploads/2024/10/Graphics2.svg"
            alt="photo"
          />
        </div>
      </div>
    </section>
  );
}
