import React, { useRef } from "react";
import Container from "react-bootstrap/Container";
import "./section8.css";
import { motion, useInView } from "framer-motion";

export default function Section8() {
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: false, amount: 0.3 });

  return (
    <section className="section8" ref={sectionRef} id="biddi">
      <div className="overlay"></div>
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          {/* <img
            className="blue"
            src="https://dojoitsolutions.com/wp-content/uploads/2024/09/yalleow.svg"
            alt=""
          /> */}
          <motion.a
            initial={{ opacity: 0, x: -200 }}
            animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : -200 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <img
              className="mobilelink8"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/10/biddi11.png"
              alt="photo"
            />
          </motion.a>
          <motion.div
            className="mainTitle8"
            initial={{ opacity: 0, x: -200 }}
            animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : -200 }}
            transition={{ duration: 0.1 }}
          >
            <h2>Biddi Cars</h2>
            <p>
              Simplifies the car buying and selling process with an intuitive
              user interface.
            </p>
          </motion.div>
          <motion.div
            className="subTitle8"
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : 200 }}
            transition={{ duration: 0.1, delay: 0.2 }}
          >
            <p>
              Biddi cars app is an innovative platform for buying and selling
              cars with ease. It offers a wide range of new and used cars,
              allowing users to search by make, model, and price. Additional
              features include vehicle assessments and direct communication with
              sellers.
            </p>
            {/* <a>DOWNLOAD APP</a> */}
          </motion.div>{" "}
        </Container>
      </div>
      <div className="dividerPortfolio2">
        <img
          className="dividerPortfolio"
          src="https://dojoitsolutions.com/wp-content/uploads/2024/10/newyellwo.svg"
          alt="photo"
        />
      </div>
    </section>
  );
}
