import Container from "react-bootstrap/Container";
import { HashLink } from "react-router-hash-link";
import { useState } from "react";
import ContactFormm from "../contactForm/formApi";

import ReCAPTCHA from "react-google-recaptcha";

import "./herotesting.css";
export default function HeroTesting() {
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const handleCaptchaChange = () => {
    setCaptchaVerified(true);
  };
  const [showaction, setshowaction] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  return (
    <section className="heroTesting heroHeight">
      <div className="container2">
        <div className="phone">
          <img
            src="https://dojoitsolutions.com/wp-content/uploads/2024/10/graphics5.svg"
            alt=""
          />
        </div>
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <h1>
            Software Testing <span> & QA</span>
          </h1>
          <p className="p-1 subtitleHero">
            Dojo integrated App quality assurance process ensures that apps are
            thoroughly tested and market-ready before launch.
          </p>

          <button
            onClick={() => {
              setshowaction(true);
            }}
          >
            Consult Our Experts
          </button>

          <p className="p-2 linksBar">
            <HashLink className="linkHero" to={"/home"}>
              Home -
            </HashLink>
            <HashLink className="linkHero" to={"/servicesPage"}>
              <span> Services</span> -
            </HashLink>
            <span> Software Testing & QA</span>
          </p>
          {showaction && (
            <div className="fixedAction">
              <div className="formAction">
                <button
                  onClick={() => {
                    setshowaction(false);
                  }}
                  className="closer"
                >
                  x
                </button>
                <ContactFormm />
              </div>
            </div>
          )}
        </Container>
      </div>
    </section>
  );
}
